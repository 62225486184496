interface NodeData {
  id: string;
  data: {
    unitWidth: string;
    headerColor: string;
    title: string;
    type: string;
    subclass: string;
    shortdescription: string;
    description: string;
    numTargets: number;
    numSources: number;
    targetLabels: string[];
    sourceLabels: string[];
    targetTypes: string[];
    sourceTypes: string[];
    params: Record<string, any>;
    action: string;
    handles: Array<{
      id: string;
      type: string;
      position: string;
      style: Record<string, number>;
      dataType: string;
      isValidConnection: boolean;
    }>;
    updateNodeData: Record<string, any>;
    status: string;
  };
  type: string;
  position: {
    x: number;
    y: number;
  };
  measured: {
    width: number;
    height: number;
  };
  selected: boolean;
  dragging: boolean;
}

export const checkWorkflowComplete = (nodes: NodeData[]): boolean => {
  // Iterate through all the nodes
  for (const node of nodes) {
    // Check if the node's subclass is "Export / Visualisation" and status is "green"
    if (
      node.data.subclass === "Export / Visualisation" &&
      node.data.status === "green"
    ) {
      return true; // If found, return true
    }
  }
  return false; // If not found, return false
};

export const isPlot = (nodes: NodeData[]): boolean => {
  return nodes.some(
    (node) => node.data.action === "plotline1D" && node.data.status === "green"
  );
};

export const addNewNode = async (
  nodes: NodeData[],
  setNodes: any,
  node_type: string,
  edges = [],
  updateNodeData: any,
  onEdit: any
) => {
  try {
    // Check if node_type is "slider"
    if (!node_type.startsWith("var")) {
      // Dynamically import the configuration file based on the node_type
      const nodeConfig = await import(
        `../components/nodeConfigs/${node_type}.js`
      );

      // Create a new node using the imported configuration

      let id = (nodes.length + 1).toString();

      const newNode = {
        id: (nodes.length + 1).toString(),
        data: {
          ...nodeConfig.default.data,
          handles: [], // Initialize handles as an empty array
          updateNodeData, // Include the updateNodeData function
          setNodes,
          onEdit,
          id,
        },
        type: "base", // Assuming 'base' type for non-slider nodes
        position: {
          x: Math.random() * 400,
          y: Math.random() * 400,
        },
      };
      // Add the new node to the state
      setNodes((nds: any) => [...nds, newNode]);
    } else {
      const nodeConfig = await import(
        `../components/nodeConfigs/Variables/${node_type}.js`
      );
      // Create a new node using the imported configuration

      let id = (nodes.length + 1).toString();

      const newNode = {
        id: (nodes.length + 1).toString(),
        data: {
          ...nodeConfig.default.data,
          handles: [], // Initialize handles as an empty array
          updateNodeData, // Include the updateNodeData function
          setNodes,
          onEdit,
          id,
        },
        type: "base", // Assuming 'base' type for non-slider nodes
        position: {
          x: Math.random() * 400,
          y: Math.random() * 400,
        },
      };
      // Add the new node to the state
      setNodes((nds: any) => [...nds, newNode]);
    }
  } catch (error) {
    console.error(
      `Error loading node configuration for type: ${node_type}`,
      error
    );
  }
};
