export default {
  data: {
    unitWidth: "400px",
    leftColumnPercentage: 20,
    menuWidth: "200px",
    headerColor: "bg-yellow-200",
    title: "File Input",
    type: "var",
    subclass: "Variable",
    shortdescription: `
    This allows you to point to a file within the project.
        `,
    description:
      "This node allows a user to define a natural language prompt used to guid an LLM",
    formType: "text",
    label: "Filename",
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Filename"],
    targetTypes: [],
    sourceTypes: ["str"],
    params: {},
    action: "fileInput",
  },
};
