import React, { useState, useCallback, useEffect } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  BackgroundVariant,
} from "@xyflow/react";

import { PlusIcon } from "@heroicons/react/24/outline";

import "@xyflow/react/dist/base.css";
import "./App.css";

import Header from "./Header";
import nodeTypes from "./utilities/nodeTypes";
import RightDrawer from "./components/Navigation/RightDrawer";
import Breadcrumb from "./components/Navigation/Breadcrumb";

import Notification from "./components/Notification";
import Sidebar from "./components/Navigation/sidebar";
import RightToolbar from "./components/Navigation/RightToolbar";
import FloatingActionButtons from "./components/Navigation/FloatingActionButtons";

import EditNodeModal from "./components/Nodes/Modals/EditNodeModal";
import NewCanvasModal from "./components/Nodes/Modals/NewCanvasModal";
import ManageProjectSharing from "./components/Nodes/Modals/ManageProjectSharingModal";

import RightDrawerPlotting from "./components/Navigation/RightDrawerPlotting";
import CustomEdge from "./components/CustomEdge";
import { checkWorkflowComplete, isPlot } from "./utilities/nodeUtils";

// Function to check if all handles of a node are connected and none of the edges are "custom"
const areAllHandlesConnectedAndNoCustomEdges = (nodeId, nodes, edges) => {
  const node = nodes.find((n) => n.id === nodeId);

  if (!node || !node.data || !node.data.handles) {
    // console.error(`Node ${nodeId} not found or has no handles`);
    return false;
  }

  let handles = node.data.handles;

  const allConnected = handles.every((handle) =>
    edges.some(
      (edge) =>
        ((edge.source === nodeId && edge.sourceHandle === handle.id) ||
          (edge.target === nodeId && edge.targetHandle === handle.id)) &&
        edge.type !== "custom"
    )
  );

  return allConnected;
};

function WorkflowCanvas({ currentUser, useTabs }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [workflowComplete, setWorkflowComplete] = useState(
    checkWorkflowComplete(nodes)
  );
  const [plotting, setPlotting] = useState(isPlot(nodes));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("summary");
  const [displayInfo, setDisplayInfo] = useState({ nodes: [], edges: [] });

  const [activeModal, setActiveModal] = useState(null); // Manages which modal is open
  const [preSelectedType, setPreSelectedType] = useState("all");

  const [isFavorite, setIsFavorite] = useState(false);
  const [isDraft, setIsDraft] = useState(true);

  const [currentProject, setCurrentProject] = useState("p1"); // Set the current project

  // Using custom hook to enable tab functionality
  const { tabs, currentTab, addTab, removeTab, selectTab } = useTabs;
  const currentTabId = tabs[currentTab];

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");

  // Helper function to update the status of each node based on connection status
  const updateNodeStatuses = (nodes, edges) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        const allConnectedAndNoCustom = areAllHandlesConnectedAndNoCustomEdges(
          node.id,
          nodes,
          edges
        );
        return {
          ...node,
          data: {
            ...node.data,
            status: allConnectedAndNoCustom ? "green" : "amber",
          },
        };
      })
    );
  };

  useEffect(() => {
    // Update node statuses whenever nodes or edges change
    updateNodeStatuses(nodes, edges);
  }, [nodes, edges]);

  // Use useEffect to update workflowComplete whenever nodes change
  useEffect(() => {
    setWorkflowComplete(checkWorkflowComplete(nodes));
    setPlotting(isPlot(nodes));
  }, [nodes]);

  const handleConnect = (params) => {
    const sourceNode = nodes.find((node) => node.id === params.source);
    const targetNode = nodes.find((node) => node.id === params.target);

    if (!sourceNode || !targetNode) {
      console.error("Source or target node not found");
      return;
    }

    console.log("Source Node:", sourceNode);
    console.log("Target Node:", targetNode);

    const sourceHandle = sourceNode.data.handles.find(
      (handle) => handle.id === params.sourceHandle
    );

    const targetHandle = targetNode.data.handles.find(
      (handle) => handle.id === params.targetHandle
    );

    if (!sourceHandle || !targetHandle) {
      console.error("Source or target handle not found");
      return;
    }

    let isValid = true;
    let errorMessage = null;

    if (
      sourceHandle.dataType === "NullType" ||
      targetHandle.dataType === "NullType"
    ) {
      isValid = true;
    } else if (sourceHandle.dataType !== targetHandle.dataType) {
      isValid = false;
      errorMessage = `Incompatible types: ${sourceHandle.dataType} and ${targetHandle.dataType}`;
    }

    if (isValid) {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            markerEnd: { type: "arrowclosed" },
            data: { isValid, errorMessage },
          },
          eds
        )
      );
    } else {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            style: { stroke: "#fcd34d", strokeWidth: 2 },
            markerEnd: { type: "arrowclosed", color: "#fcd34d" },
            data: { isValid, errorMessage },
            type: "custom",
          },
          eds
        )
      );
    }
  };

  const onConnect = useCallback((params) => handleConnect(params), [nodes]);

  const onEdgeClick = useCallback((event, edge) => {
    if (!edge.data?.isValid) {
      setNotificationMessage(edge.data.errorMessage);
      setNotificationTitle("Edge Connection Error");
      setShowNotification(true);
    }
  }, []);

  const handleEdgesDelete = (deletedEdges) => {
    deletedEdges.forEach((edge) => {
      updateNodeStatuses(nodes, edges);
    });
  };

  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      handleEdgesDelete(deletedEdges);
    },
    [setNodes]
  );

  const handleAddNode = (type = "defaultType") => {
    setPreSelectedType(type);
    setActiveModal("EditNodeModal");
  };

  return (
    <div
      className="workflow-canvas"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <Header
        className="fixed top-0 left-0 right-0 z-20"
        setActiveModal={setActiveModal}
        currentUser={currentUser}
        tabs={tabs}
        currentTabId={currentTabId}
        selectTab={selectTab}
      />

      <div className="min-h-full relative pt-16">
        <div
          className="relative bg-gray-100"
          style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onEdgesDelete={onEdgesDelete}
            onEdgeClick={onEdgeClick}
            nodeTypes={nodeTypes}
            edgeTypes={{ custom: CustomEdge }}
            fitView
            className="absolute top-0 left-0 w-full h-full z-0 bg-gray-200"
          >
            <Background
              id="2"
              gap={20}
              color="#111827"
              variant={BackgroundVariant.Dots}
            />
          </ReactFlow>

          <div className="absolute top-6 left-0 right-0 z-10 flex justify-between items-center px-4 h-20">
            <Breadcrumb
              currentProject={currentProject}
              currentTabId={currentTabId}
              isFavorite={isFavorite}
              isDraft={isDraft}
              setIsFavorite={setIsFavorite}
              setIsDraft={setIsDraft}
            />
            {/* Mock Component that shows how to call our API using the generated typescript-axios client */}
            <RightToolbar
              setActiveModal={setActiveModal}
              currentProject={currentProject}
            />
          </div>
          <FloatingActionButtons
            setActiveTab={setActiveTab}
            setIsDrawerOpen={setIsDrawerOpen}
            workflowComplete={workflowComplete}
            plotting={plotting}
          />

          {/* <div
            className="absolute left-0 right-0 z-10 flex justify-between items-center px-4 h-20"
            style={{ top: "40%", transform: "translateY(-50%)" }}
          >
            <Sidebar
              nodes={nodes}
              setNodes={setNodes}
              setActiveModal={setActiveModal}
              preSelectedType={preSelectedType}
              setPreSelectedType={setPreSelectedType}
            />
          </div> */}

          <RightDrawer
            open={isDrawerOpen}
            setOpen={setIsDrawerOpen}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            displayInfo={displayInfo}
            currentUser={currentUser}
            currentProject={currentProject}
            currentTabId={currentTabId}
          />

          {/* Add Node Button */}
          <div className="absolute bottom-25 left-6">
            <button
              onClick={() => handleAddNode("all")}
              className="w-20 h-20 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
            >
              <PlusIcon className="h-10 w-10 text-gray-600 hover:text-white" />
            </button>
          </div>

          {/* Conditionally render modals based on activeModal */}
          {activeModal === "EditNodeModal" && (
            <EditNodeModal
              nodes={nodes}
              setNodes={setNodes}
              preSelectedType={preSelectedType}
              open={true}
              setOpen={setActiveModal}
            />
          )}

          {activeModal === "NewCanvasModal" && (
            <NewCanvasModal open={true} setOpen={setActiveModal} />
          )}

          {activeModal === "ManageProjectSharingModal" && (
            <ManageProjectSharing
              open={true}
              setOpen={setActiveModal} // To close the modal
              currentUser={currentUser}
              currentProject={currentProject}
            />
          )}

          <Notification
            show={showNotification}
            setShow={setShowNotification}
            message={notificationMessage}
            title={notificationTitle}
          />
        </div>
      </div>
    </div>
  );
}

export default WorkflowCanvas;
