export default {
  data: {
    unitWidth: "400px",
    leftColumnPercentage: 20,
    menuWidth: "200px",
    headerColor: "bg-yellow-200",
    title: "Prompt Input",
    type: "var",
    subclass: "Variable",
    shortdescription: `
        This node allows a user to define a natural language prompt used to guid an LLM
        `,
    description:
      "This node allows a user to define a natural language prompt used to guid an LLM",
    formType: "textBox",
    label: "Prompt",
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Prompt"],
    targetTypes: [],
    sourceTypes: ["str"],
    params: {},
    action: "promptInput",
  },
};
