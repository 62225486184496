import React, { memo, useState, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";

import generateHandles from "./NodeComponents/generateHandles";
import DynamicList from "./NodeComponents/DynamicList";
import GeneralNode from "./GeneralNode";
import VariableNode from "./VariableNode";

const statusColorMap = {
  green: "bg-green-400",
  amber: "bg-yellow-400",
  red: "bg-red-400",
};

function BaseNode({ data }) {
  const {
    unitWidth,
    title,
    headerColor,
    description,
    onEdit,
    setNodes,
    id,
    numTargets = 0,
    numSources = 0,
    targetLabels = [],
    sourceLabels = [],
    targetTypes = [],
    sourceTypes = [],
    status = "amber",
    dynamicList = false,
    listExtraInputs = [],
  } = data;

  const headerHeight = 60;
  const {
    handles: staticHandles,
    offsetTarget,
    offsetSource,
  } = generateHandles(
    numTargets,
    numSources,
    targetTypes,
    sourceTypes,
    headerHeight
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [extraInputs, setextraInputs] = useState(listExtraInputs);
  const [dynamicHandles, setDynamicHandles] = useState([]);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const leftColumnWidth = data?.leftColumnPercentage || 50;
  const rightColumnWidth = 100 - leftColumnWidth;

  // Sync dynamicHandles with node.data.handles
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                handles: [...(staticHandles || []), ...(dynamicHandles || [])], // Merge static and dynamic handles
              },
            }
          : node
      )
    );
  }, [dynamicHandles, staticHandles, id, setNodes]);

  // Add dynamic handles based on extraInputs
  const addDynamicHandle = () => {
    const newHandle = {
      id: `extra_handle_${extraInputs.length + 1}`,
      type: "target",
      position: Position.Left,
      style: {
        top: 60 + 60 + 30 + 60 * extraInputs.length,
        position: "absolute",
      },
      dataType: "str",
    };
    setDynamicHandles((prevHandles) => [...prevHandles, newHandle]);
  };

  const deleteDynamicHandle = (handleId) => {
    setDynamicHandles((handles) =>
      handles.filter((handle) => handle.id !== handleId)
    );
  };

  return (
    <div>
      {data.type === "var" ? (
        <VariableNode
          data={data}
          rightColumnWidth={rightColumnWidth}
          leftColumnWidth={leftColumnWidth}
        />
      ) : (
        <div className="rounded-lg border border-gray-400">
          <GeneralNode
            data={data}
            statusColorMap={statusColorMap}
            offsetTarget={offsetTarget}
            offsetSource={offsetSource}
            toggleExpansion={toggleExpansion}
            isExpanded={isExpanded}
          />

          {dynamicList && (
            <div
              className="flex flex-col divide-y divide-gray-100 px-2 text-sm text-gray- leading-6 bg-gray-50 rounded-b-lg border-gray-400"
              style={{ width: unitWidth }}
            >
              <DynamicList
                headerHeight={headerHeight}
                extraInputs={extraInputs}
                setextraInputs={setextraInputs}
                addDynamicHandle={addDynamicHandle} // Call this when extraInputs changes
              />
            </div>
          )}
          {/* Render Static Handles */}
          {staticHandles.map((handleConfig) => (
            <Handle
              key={handleConfig.id}
              id={handleConfig.id}
              type={handleConfig.type}
              position={handleConfig.position}
              className="rounded-full w-2 h-2 !bg-black ring-2 ring-gray-00 ring-offset-2 ring-offset-gray-50"
              style={handleConfig.style}
            />
          ))}

          {/* Render Dynamic Handles */}
          {dynamicHandles.map((handle, i) => (
            <Handle
              key={handle.id}
              id={handle.id}
              type={handle.type}
              position={handle.position}
              className="rounded-full w-2 h-2 !bg-black ring-2 ring-gray-00 ring-offset-2 ring-offset-gray-50"
              style={{
                top: 340 + 58 * i,
                position: "absolute",
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(BaseNode);
