import React, { useState } from "react";
import Dropdown from "./FormUtils/Dropdown";
import ToggleSwitch from "./FormUtils/ToggleSwitch";

export default function NodeInput({ data, rightColumnWidth = 50 }) {
  const [value, setValue] = useState(data?.value || "");

  const handleSliderChange = (event) => {
    setValue(parseInt(event.target.value, 10));
    if (data.onChange) {
      data.onChange(event.target.value);
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setValue(selectedOption);
    if (data.onChange) {
      data.onChange(selectedOption);
    }
  };

  const handleToggleChange = (newValue) => {
    setValue(newValue);
    if (data.onChange) {
      data.onChange(newValue);
    }
  };

  const handleIntegerChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    if (data.onChange) {
      data.onChange(newValue);
    }
  };

  const handleFloatChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
    if (data.onChange) {
      data.onChange(newValue);
    }
  };

  const handleTextChange = (event) => {
    setValue(event.target.value);
    if (data.onChange) {
      data.onChange(event.target.value);
    }
  };

  const handleLoadFromPromptLibrary = () => {
    console.log("Load from Prompt Library clicked");
    // Add your logic for loading from the prompt library here
  };

  return (
    <div
      className="flex-1 flex items-center justify-center px-2"
      style={{ width: `${rightColumnWidth}%` }}
    >
      {data?.formType === "toggle" ? (
        <ToggleSwitch enabled={value} setEnabled={handleToggleChange} />
      ) : data?.formType === "slider" ? (
        <input
          type="range"
          min={data?.min || 0}
          max={data?.max || 100}
          value={value}
          onChange={handleSliderChange}
          className="slider-input appearance-none w-full h-2 bg-blue-200 rounded-full"
        />
      ) : data?.formType === "dropdown" ? (
        <Dropdown
          options={data?.options || []}
          selected={value}
          setSelected={handleDropdownChange}
          width={data?.menuWidth}
        />
      ) : data?.formType === "integer" ? (
        <input
          type="number"
          value={value}
          onChange={handleIntegerChange}
          className="appearance-none w-full h-8 px-2 rounded-md bg-yellow-50 border border-yellow-700 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
      ) : data?.formType === "float" ? (
        <input
          type="number"
          step="any"
          value={value}
          onChange={handleFloatChange}
          className="appearance-none w-full h-8 px-2 rounded-md bg-yellow-50 border border-yellow-700 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
      ) : data?.formType === "textBox" ? (
        <div className="relative w-full">
          <label htmlFor="textBox" className="sr-only">
            Text Input
          </label>
          <textarea
            id="textBox"
            name="textBox"
            value={value}
            onChange={handleTextChange}
            placeholder={data?.placeholder || "Write something..."}
            rows={4}
            className="block w-full resize-y bg-yellow-50 border border-yellow-700 py-1 text-gray-600 placeholder:text-gray-400 focus:ring-0 text-sm overflow-auto rounded-md"
          />
          {/* git st
           */}
        </div>
      ) : (
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="appearance-none w-full h-8 px-2 rounded-md bg-yellow-50 border border-yellow-700 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200"
        />
      )}
    </div>
  );
}
