import React, { useState } from "react";
import { HomeIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/24/solid"; // Importing the solid star for filled icon
import { StarIcon as StarOutlineIcon } from "@heroicons/react/24/outline"; // Importing the outline star for non-filled icon

const pages = [
  { name: "UKAEA", href: "#", current: false },
  { name: "FullGyroX.flw", href: "#", current: true },
];

export default function Breadcrumb() {
  const [isFavorite, setIsFavorite] = useState(false); // State to track if the breadcrumb is marked as favorite
  const [status, setStatus] = useState("draft"); // You can dynamically set this status based on your app's logic

  return (
    <div className="p-2 pr-6 bg-white border border-gray-400 rounded-md h-14">
      {" "}
      {/* Padding increased on right side */}
      <nav aria-label="Breadcrumb" className="flex items-center">
        <ol
          role="list"
          className="flex space-x-2 rounded-md px-4 py-0.5 text-xs"
        >
          {" "}
          {/* Reduced padding and text size */}
          <li className="flex">
            <div className="flex items-center">
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  aria-hidden="true"
                  className="h-4 w-4 flex-shrink-0"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="flex">
              <div className="flex items-center">
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                  className="h-full w-4 flex-shrink-0 text-gray-200"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <a
                  href={page.href}
                  aria-current={page.current ? "page" : undefined}
                  className="ml-2 text-xs font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>

        {/* Favorite Star */}
        <button
          onClick={() => setIsFavorite(!isFavorite)}
          className="ml-4 focus:outline-none"
        >
          {isFavorite ? (
            <StarIcon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
          ) : (
            <StarOutlineIcon
              className="h-5 w-5 text-gray-400 hover:text-gray-500"
              aria-hidden="true"
            />
          )}
          <span className="sr-only">Mark as favorite</span>
        </button>

        {/* Status Tag */}
        <span
          className={`ml-4 px-2 py-0.5 rounded text-xs font-medium ${
            status === "draft"
              ? "bg-amber-200 text-amber-800"
              : "bg-green-200 text-green-800"
          }`}
        >
          {status.toUpperCase()}
        </span>
      </nav>
    </div>
  );
}
