import React, { memo, useState, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import {
  InformationCircleIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import MarkdownRenderer from "../../utilities/MarkdownRenderer";
import NodeDropdownMenu from "./NodeComponents/NodeDropdownMenu";
import NodeInput from "./NodeInput";

export default function GeneralNode({
  data,
  statusColorMap,
  toggleExpansion,
  isExpanded,
  offsetTarget,
  offsetSource,
}) {
  const {
    unitWidth,
    title,
    headerColor,
    description,
    onEdit,
    setNodes,
    id,
    numTargets = 0,
    numSources = 0,
    targetLabels = [],
    sourceLabels = [],
    targetTypes = [],
    sourceTypes = [],
    status = "amber",
    dynamicList = false,
    listExtraInputs = [],
  } = data;

  return (
    <div
      className="relative border-b border-gray-900/5 bg-gray-50 p-0 rounded-lg"
      style={{ width: unitWidth }}
    >
      <span
        className={`absolute -top-1 -right-1 block h-4 w-4 rounded-full ring-2 ring-white ${statusColorMap[status]}`}
      />

      <div
        className={`flex items-center justify-between ${headerColor} py-2 px-4 rounded-t-lg`}
        style={{ width: unitWidth, height: "60px" }}
      >
        <div className="text-lg font-bold leading-6 text-gray-900">{title}</div>

        <NodeDropdownMenu
          menuItems={[
            {
              label: "Edit / View Parameters",
              icon: PencilSquareIcon,
              onClick: onEdit,
            },
            {
              label: "Duplicate",
              icon: DocumentDuplicateIcon,
              onClick: () => console.log("Duplicate clicked"),
            },
            {
              label: "Delete",
              icon: TrashIcon,
              onClick: () => console.log("Delete clicked"),
            },
          ]}
        />
      </div>

      <div
        className="flex flex-row items-start p-4 bg-gray-175 relative rounded-b-lg"
        style={{ width: unitWidth }}
      >
        {data?.formType && <NodeInput data={data} />}
        {/* Left Section */}
        <div className="flex-1 flex flex-col">
          {Array.from({ length: numTargets }).map((_, index) => (
            <div
              key={`target-${index}`}
              className="flex-1 text-sm text-gray-600"
              style={{
                position: "absolute",
                top: (index + 1) * offsetTarget + "px",
                left: "10px",
                transform: "translateY(-55%)",
              }}
            >
              {targetLabels[index] || `Target ${index + 1}`}
            </div>
          ))}
        </div>

        {/* Right Section */}
        <div
          className="flex-1 flex flex-col text-right justify-center"
          style={{
            height: 30 * (Math.max(numTargets, numSources) + 1) + "px",
          }}
        >
          {Array.from({ length: numSources }).map((_, index) => (
            <div
              key={`source-${index}`}
              className="flex-1 text-sm text-gray-600"
              style={{
                position: "absolute",
                top: (index + 1) * offsetSource + "px",
                right: "10px",
                transform: "translateY(-55%)",
              }}
            >
              {sourceLabels[index] || `Source ${index + 1}`}
            </div>
          ))}
        </div>

        {/* Toggle Button */}
        <button
          type="button"
          onClick={toggleExpansion}
          className="absolute bottom-1 right-1 text-gray-500 hover:text-gray-800"
        >
          {isExpanded ? (
            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
          ) : (
            <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </button>
      </div>

      {isExpanded && (
        <div
          className="divide-y divide-gray-100 px-6 py-4 text-sm text-gray- leading-6 bg-yellow-50 rounded-lg border-gray-400"
          style={{ width: unitWidth }}
        >
          <MarkdownRenderer markdownString={description} />
        </div>
      )}
    </div>
  );
}
