import React, { useState } from "react";
import { Handle, Position } from "@xyflow/react";
import NodeInput from "./NodeInput";

export default function VariableNode({
  data,
  rightColumnWidth = 50,
  leftColumnWidth = 50,
}) {
  return (
    <div
      className="rounded-lg bg-yellow-100 border border-gray-300 shadow-sm p-4 flex"
      style={{ width: data?.unitWidth }}
    >
      {/* Left Section (Label) */}
      <div
        className="flex flex-col justify-start"
        style={{ width: `${leftColumnWidth}%` }}
      >
        <span className="text-gray-700 font-semibold">
          {data?.label || "Variable"}
        </span>
      </div>
      {data?.formType && (
        <NodeInput data={data} rightColumnWidth={rightColumnWidth} />
      )}

      {/* Handle on the right, positioned exactly at the boundary */}
      <Handle
        type="source"
        position={Position.Right}
        id="source_1"
        className="rounded-full w-2 h-2"
        style={{
          right: "-3px", // Move it slightly outside the boundary
          top: "50%",
          transform: "translateY(-50%)", // Vertically center it
        }}
      />
    </div>
  );
}
